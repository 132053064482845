










import { SfLoader } from '@storefront-ui/vue';
import {
  defineComponent,
  useRoute,
  ssrRef, useContext,
} from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';

// @ts-ignore
import { CacheTagPrefix, useCache } from '@gemini/vsf-cache';
import { useUrlResolver } from '~/composables/useUrlResolver';
import cacheControl from '~/helpers/cacheControl';
import { isLookBookPage } from '~/helpers/lookbook/lookbookData';
import { useI18n } from '~/helpers/hooks/usei18n';

interface RootComponent {
  name: string;
  props?: object;
}

function isRedirect(redirectCode: number | undefined): boolean {
  const redirectCodes = new Set()
    .add(301)
    .add(302);
  return redirectCodes.has(redirectCode);
}

export default defineComponent({
  name: 'RoutePage',
  components: {
    SfLoader,
    ProductPage: () => import('~/pages/Product.vue'),
    CategoryPage: () => import('~/pages/Category.vue'),
    CmsPage: () => import('~/pages/Page.vue'),
  },
  middleware: cacheControl({
    'max-age': 60,
    'stale-when-revalidate': 5,
  }),
  transition: 'fade',
  setup() {
    const CacheTagPrefixUrl = 'U'; // TODO move to cache lib
    const { error: nuxtError, redirect, app } = useContext();
    const route = useRoute();
    const {
      params: { path },
    } = route.value;
    const {
      result, search: resolveUrl, loading: urlResolverLoading, error: urlResolverError,
    } = useUrlResolver();
    const { locale } = useI18n();
    const identifier = `${locale}/${path}`;
    const rootComponent = ssrRef<RootComponent | null>(null);
    const { addTags } = useCache();

    onSSR(async () => {
      rootComponent.value = { name: '' };
      await resolveUrl();

      const component: RootComponent = { name: '' };
      const urlRewrite = result?.value;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const shouldRedirect = isRedirect(urlRewrite?.redirectCode);

      if (shouldRedirect && urlRewrite?.urlPath && urlRewrite?.urlPath !== path) {
        const regx = /^(?:[a-z]+:)?\/\//i;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const redirectPath = regx.test(urlRewrite.urlPath)
          ? urlRewrite.urlPath
          : app.localePath(`/${urlRewrite.urlPath.replace(/^(\/)/, '')}`);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        redirect(urlRewrite.redirectCode, redirectPath);
        return;
      }

      if (urlResolverError?.value?.search) {
        nuxtError({ statusCode: 500 });
      } else {
        switch (urlRewrite?.entityCode) {
          case 'Product':
            component.name = 'ProductPage';
            component.props = {
              productId: urlRewrite?.entityId,
            };
            break;
          case 'ProductList':
            component.name = 'CategoryPage';
            component.props = {
              categoryId: urlRewrite?.entityId,
            };
            break;
          default:
            // TODO define a specific case once implemented DXP cms service
            if (!isLookBookPage(identifier)) {
              component.name = 'CmsPage';
              component.props = {
                identifier: path,
              };
            }
            break;
        }

        if (!component?.name) {
          nuxtError({ statusCode: 404 });
        } else {
          // avoid caching cms, the cache doesn't work with nuxtError
          // eslint-disable-next-line no-lonely-if
          if (component.name !== 'CmsPage') {
            addTags([
              { prefix: CacheTagPrefix.View, value: 'urlrewrite' },
              { prefix: CacheTagPrefixUrl, value: urlRewrite?.entityId },
            ]);
          }
        }
        rootComponent.value = component;
      }
    });

    return {
      rootComponent,
      loading: urlResolverLoading,
    };
  },
  head() {
    return {
      title: 'Lazzari',
      meta: [
        { hid: 'robots', name: 'robots', content: 'index, follow' },
      ],
    };
  },
});
